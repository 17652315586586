import { NgModule, ErrorHandler } from '@angular/core';
import { CommonModule } from "@angular/common";
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
// import { StatusBar } from '@ionic-native/status-bar/ngx';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';
// import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { IonicStorageModule } from '@ionic/storage';
import { Storage } from '@ionic/storage';
import { JwtModule, JWT_OPTIONS } from '@auth0/angular-jwt';
import { GpsService } from './services/gps.service';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { FormsModule } from '@angular/forms';
import { ScanskuPageModule } from './members/scansku/scansku.module';
//import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { InteractiveuxService } from "./services/interactiveux.service"




export function jwtOptionsFactory(storage) {
  return {
    tokenGetter: () => {
      return storage.get('access_token');
    },
    whitelistedDomains: ['marketforce.co.za', 'mfdev.marketforce.co.za', 'mfdevmarketforce.co.za','apidev.marketforce.co.za', 'notice.marketforce.co.za', 'localhost']
  }
}



@NgModule({
  declarations: [
    AppComponent    
  ],
  entryComponents: [],
  imports: [BrowserModule,
    BrowserAnimationsModule,
    CommonModule,     
    HttpClientModule,          
    IonicModule.forRoot({
      backButtonText: '',     
      mode: 'ios',
      swipeBackEnabled: true,
      animated: true,
      hardwareBackButton: false,
      statusTap: true,
              
     }),
     AppRoutingModule, 
     IonicStorageModule.forRoot({
      // driverOrder: [ 'sqlite','indexeddb', 'websql']
      driverOrder: [ 'indexeddb', 'websql']
     }), 
     JwtModule.forRoot({
        jwtOptionsProvider: {
          provide: JWT_OPTIONS,
          useFactory: jwtOptionsFactory,
          deps: [Storage],

        }      
    }),    
    FormsModule, 
    //ImageModalPageModule, 
    ScanskuPageModule,   
    ServiceWorkerModule.register('/ngsw-worker.js', { enabled: environment.production })
  ],    
  providers: [
    // StatusBar,
    // SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },   
     Geolocation,
     GpsService,
     InteractiveuxService

  ],
  exports: [CommonModule],
  bootstrap: [AppComponent]
})
export class AppModule {}
