import { Router } from '@angular/router';
import { AuthenticationService } from './services/authentication.service';
import { Component,OnInit } from '@angular/core';
 
import { Platform, NavController, MenuController } from '@ionic/angular';
// import { StatusBar } from '@ionic-native/status-bar/ngx';
// import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { Storage } from '@ionic/storage'

import { Pages } from './interfaces/pages';
import { SwUpdate } from "@angular/service-worker";
import { InteractiveuxService } from "./services/interactiveux.service"



 
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html'
})
export class AppComponent implements OnInit {

  public appPages: Array<Pages>; 
  stored: any = {};
  accountUser: any = {};
  isAgent: boolean = false;
  isMultiDataSet: boolean = false;
  activeSupplier: any = {};
  activeSupplierImg: any = {};
  activeSupplierID: any;
  linkedSuppliers: any = {};
  appIsOnline: any;
  appIsOnDevice: any;
  relatedApps: any;
  

  constructor(
    private platform: Platform,
    // private splashScreen: SplashScreen,
    // private statusBar: StatusBar,
    private authService: AuthenticationService,
    private router: Router,
    public navCtrl: NavController,
    public menuCtrl: MenuController, 
    public swUpdate: SwUpdate,
    private storage: Storage,
    public iux: InteractiveuxService
  ) 
  {

    
  }

  ngOnInit() {

      // console.log('ACCOUNT TYPE',accountUserData['DataResult']['UserAccount']['Type']);
      this.accountUser['Type']                  = this.getStoredValue('ACCOUNT_type');
      this.accountUser['LinkedSuppliersCount']  = this.getStoredValue('ACCOUNT_LinkedSuppliersCount');

      if( this.accountUser['Type'] == 10) {
          this.isAgent = true;

      }
      if (this.accountUser['LinkedSuppliersCount'] > 1) {
          this.isMultiDataSet = true;          
      } else {

          this.isMultiDataSet = false;
          this.isAgent        = false;
      }

       
    

    //this.events.subscribe('activeSupplier', (activeSupplierData) =>{   

      this.getStoredValue('ActiveSupplier').then(activeSupplierID => {
        this.activeSupplierID = activeSupplierID;
        this.linkedSuppliers = this.getStoredValue('ACCOUNT_LinkedSuppliers');

        for (let si of this.linkedSuppliers) {
            // console.log(si);
            if (si['SupplierID'] === activeSupplierID) {
              this.activeSupplier    = si['SupplierName'];
              this.activeSupplierImg = si['Img'];
              break;
            }
          } 
        
        });
    
    window.addEventListener("deviceready", this.onDeviceReady, false);
    this.initializeApp();
    

    
  }
 
  initializeApp() {
    this.platform.ready().then(() => {
        // this.statusBar.styleDefault();
        // this.splashScreen.hide();

        console.debug('NAV ngOnInit- has appVersion',navigator.appVersion); 
        console.debug('NAV ngOnInit- has geolocation',navigator.geolocation);
        console.debug('NAV ngOnInit- mediaDevices',navigator.mediaDevices);
        console.debug('NAV ngOnInit- onLine',navigator.onLine);
  
        this.authService.authenticationState.subscribe(state => {
          if (state) {
            this.iux.vibez('haptic');
            this.router.navigate(['members', 'selectstore']);
          } else {
            this.router.navigate(['login']);
          }
        });
  
    });
  }



  onDeviceReady() {
     //console.log(this.device.cordova);
     window.addEventListener("visibilitychange", this.handleVisibilityChange, false);
     window.addEventListener("connectivitychange", this.handleConectivityChange, false);

     console.info('NAV onDeviceReady- has appVersion',navigator.appVersion); 
     console.info('NAV onDeviceReady- has geolocation',navigator.geolocation);
     console.info('NAV onDeviceReady- mediaDevices',navigator.mediaDevices);
     console.info('NAV onDeviceReady- onLine',navigator.onLine);
     console.log('NAV onDeviceReady- vibrate',navigator.vibrate);
     //console.log('NAV onDeviceReady- permissions',navigator.permissions.query());



     
  }

  
  


  handleVisibilityChange() {
    if (document.hidden) {
      console.info('Visibility Change: Hidden');
    } else  {
      console.info('Visibility Change: Visible');
    }
  }

  handleConectivityChange() {
    if (navigator.onLine) {
      console.info('Connection Change: Online');
    } else  {
      console.info('Connection Change: Offline');
    }
  }



  


  async getStoredValue(key: string) {
    await this.storage.get(key).then((val) => {         
          return val;
    }).catch((error) => {
          console.error('ERROR: Storage get for ' + key + ' ', error);          
    });
 }

  reload() { 
          
        this.swUpdate.activateUpdate().then(() => {
            document.location.reload();                             
        })     
        //window.location.reload();
          
  }

  closeSideMenu() {
    //this.menuCtrl.enable(true, 'main');
    this.menuCtrl.close('main');
  }


  logout() {
    this.authService.logout();
  }

  navSelectSupplier() {
    this.navCtrl.navigateRoot('/members/selectsupplier');
  }


 

  

}