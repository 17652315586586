
<ion-header>
    
        <ion-toolbar color="primary">
      
          <ion-buttons slot="start">
              <ion-back-button slot=”icon-only”  defaultHref="/members/dashboard" *ngIf="!isModal"></ion-back-button>
              <ion-button slot=”icon-only” *ngIf="isModal" (click)="closeModal()"><ion-icon name="close"></ion-icon></ion-button>
          </ion-buttons>

          <ion-buttons slot="end">
            <ion-button slot=”icon-only”  (click)="toggleSearchBar()">
                <ion-icon name="arrow-dropup" class="text06" *ngIf="showSearchBar"></ion-icon><ion-icon name="search"></ion-icon>
            </ion-button>
          </ion-buttons>
      
          <ion-title>       
                <ion-text color="light">{{ title }}</ion-text>       
          </ion-title>
        </ion-toolbar>
        <!-- <ion-toolbar color="dark">
            <ion-searchbar debounce="700" showCancelButton="true" [(ngModel)]="searchTerm" (ionChange)="searchChanged($event)"  value="{{ this.lastScanResult }}" animated clear-icon></ion-searchbar>      
          </ion-toolbar> -->

          <div *ngIf="showSearchBar"> 
            <ion-toolbar color="logo" class="ion-no-padding">
                <ion-searchbar  [(ngModel)]="searchTerm"  (ionChange)="searchChanged($event)" debouce="500" inputmode="search" spellcheck="true" value="{{ this.lastScanResult }}" autocorrect="true" mode="md"  animated clear-icon></ion-searchbar>      
            </ion-toolbar>
        </div>
</ion-header>
      
      <ion-content>

            <ion-progress-bar type="indeterminate"  *ngIf="isLoading"></ion-progress-bar>

                  


                        <div *ngIf="!scannerIsRunning"   style="width: 100%" class="ion-text-center">  
                            <ion-button fill="outline"  expand="block" color="primary" strong="true" size="default" style="width: 99%;" (click)="startScanner()">                                
                                <ion-icon  name="barcode"  style="margin-right: 5px;"></ion-icon> Start Scanner
                                <ion-ripple-effect></ion-ripple-effect>
                            </ion-button>
                        </div>
                        <div *ngIf="scannerIsRunning" style="width: 100%" class="ion-text-center">  
                            <ion-button fill="solid"  expand="block"  color="danger" strong="true" size="default" style="width: 99%" (click)="stopScanner()" >                                
                                <ion-icon  name="barcode" style="margin-right: 5px;"></ion-icon> Stop Scanner
                                <ion-ripple-effect></ion-ripple-effect>
                            </ion-button>
                        </div>

                       <!-- <div  style="width: 50%" class="ion-text-center" >
                            <ion-button fill="solid" expand="block" fill="solid" color="primary"  class="fw500 text07" style="width: 95%">
                                    <ion-icon  name="camera"  style="margin-right: 5px;"></ion-icon>
                        <label for="file" style="font-size: 1em;"> Photo Scan </label> <input  id="file" class="fileInput"  #fileInput type="file" (change)="scanPhoto(fileInput.files)" />
                                <ion-ripple-effect></ion-ripple-effect>                                
                            </ion-button>
                        </div> -->

       


    
    

    <ng-container *ngIf="!noResults; else noResultsTemplate">
        <ion-row *ngFor="let item of searchListed">

        <ng-container *ngIf="!isModal">          
            <ion-card tappable routerDirection="forward"  routerLink="/members/skuinfo/{{item.ArticleNumber}}">           
                <ion-card-header class="ion-no-padding">
                      
                          <ion-grid>
                                  <ion-row>
                                      <ion-col>
                                              <ion-label class="fw600 ion-text-wrap" color="dark" style="display:block;">{{ item.ArticleDescription }}</ion-label>
                                              <ion-card-subtitle class="ion-float-left">{{ item.Barcode }}</ion-card-subtitle>
                                              <ion-card-subtitle class="ion-float-right"> {{ item.ArticleStatusDesc }}</ion-card-subtitle>
                                      </ion-col>
                                      <ion-col size="1">
                                              <ion-icon  size="large" name="arrow-dropright"></ion-icon>   
                                      </ion-col>                 
                                  </ion-row>
                          </ion-grid>  
                        
                </ion-card-header>
                          
                    <!--<ion-item >                                
                          <ion-icon name="cube" slot="start"></ion-icon>
                          <ion-label><span class="ion-float-left">Stock on Hand</span><span class="ion-float-right">{{ item.SOHQty }}</span></ion-label>                  
                    </ion-item>
                    <ion-item >                                
                        <ion-icon name="alert" slot="start"></ion-icon>
                        <ion-label><span class="ion-float-left">Listing Status</span><span class="ion-float-right">{{ item.ArticleStatusCode }} {{ item.ArticleStatusDesc }}</span></ion-label>                  
                  </ion-item>    -->                           

            </ion-card>
        </ng-container>

        <ng-container *ngIf="isModal">                      
                           
                        <ion-card tappable (click)="selectSKUModal(item.ArticleNumber, item.ArticleDescription, item.Barcode)">
                            <ion-card-header class="ion-no-padding">
                                  
                                      <ion-grid>
                                              <ion-row>
                                                  <ion-col>
                                                          <ion-label class="fw600 ion-text-wrap" color="dark" style="display:block;">{{ item.ArticleDescription }}</ion-label>
                                                          <ion-card-subtitle class="ion-float-left">{{ item.Barcode }}</ion-card-subtitle>
                                                          <ion-card-subtitle class="ion-float-right"> {{ item.ArticleStatusDesc }}</ion-card-subtitle>
                                                  </ion-col>
                                                  <ion-col size="1">
                                                          <ion-icon  size="large" name="arrow-dropright"></ion-icon>   
                                                  </ion-col>                 
                                              </ion-row>
                                      </ion-grid>  
                                    
                            </ion-card-header>
                                      
                            <!--    <ion-item >                                
                                      <ion-icon name="cube" slot="start"></ion-icon>
                                      <ion-label><span class="ion-float-left">Stock on Hand</span><span class="ion-float-right">{{ item.SOHQty }}</span></ion-label>                  
                                </ion-item>
                                <ion-item >                                
                                    <ion-icon name="alert" slot="start"></ion-icon>
                                    <ion-label><span class="ion-float-left">Listing Status</span><span class="ion-float-right">{{ item.ArticleStatusCode }} {{ item.ArticleStatusDesc }}</span></ion-label>                  
                              </ion-item>  -->                            
                             
                        </ion-card>

            </ng-container>
        </ion-row>  
        
        
        <ion-row *ngFor="let item of searchNotListed">

            <ng-container *ngIf="!isModal">          
                <ion-card >           
                    <ion-card-header class="ion-no-padding">
                          
                              <ion-grid>
                                      <ion-row>
                                          <ion-col>
                                                  <ion-label class="fw600 ion-text-wrap" color="dark" style="display:block;">{{ item.ArticleDescription }}</ion-label>
                                                  <ion-card-subtitle class="ion-float-left">{{ item.Barcode }}</ion-card-subtitle>                                                  
                                          </ion-col>                                                       
                                      </ion-row>
                                      <ion-row>
                                            <ion-text class="text07 fw700" color="danger">Not Listed in current store</ion-text>
                                      </ion-row>

                              </ion-grid>  
                            
                    </ion-card-header>
                              
                    <!--    <ion-item >                                
                              <ion-icon name="cube" slot="start"></ion-icon>
                              <ion-label><span class="ion-float-left">Stock on Hand</span><span class="ion-float-right">{{ item.SOHQty }}</span></ion-label>                  
                        </ion-item>
                        <ion-item >                                
                            <ion-icon name="alert" slot="start"></ion-icon>
                            <ion-label><span class="ion-float-left">Listing Status</span><span class="ion-float-right">{{ item.ArticleStatusCode }} {{ item.ArticleStatusDesc }}</span></ion-label>                  
                      </ion-item>  -->                            
    
                </ion-card>
            </ng-container>
    
            <ng-container *ngIf="isModal">                      
                               
                            <ion-card tappable (click)="selectSKUModal(item.ArticleNumber, item.ArticleDescription, item.Barcode)">
                                <ion-card-header class="ion-no-padding">
                                      
                                          <ion-grid>
                                                  <ion-row>
                                                      <ion-col>
                                                              <ion-label class="fw600 ion-text-wrap" color="dark" style="display:block;">{{ item.ArticleDescription }}</ion-label>
                                                              <ion-card-subtitle class="ion-float-left">{{ item.Barcode }}</ion-card-subtitle>
                                                             
                                                      </ion-col>
                                                      <ion-col size="1">
                                                              <ion-icon  size="large" name="arrow-dropright"></ion-icon>   
                                                      </ion-col>                 
                                                  </ion-row>
                                                  <ion-row>
                                                    <ion-text class="text07 fw700" color="danger">Not Listed in current store</ion-text>
                                              </ion-row>
                                          </ion-grid>  
                                        
                                </ion-card-header>
                                          
                                <!--    <ion-item >                                
                                          <ion-icon name="cube" slot="start"></ion-icon>
                                          <ion-label><span class="ion-float-left">Stock on Hand</span><span class="ion-float-right">{{ item.SOHQty }}</span></ion-label>                  
                                    </ion-item>
                                    <ion-item >                                
                                        <ion-icon name="alert" slot="start"></ion-icon>
                                        <ion-label><span class="ion-float-left">Listing Status</span><span class="ion-float-right">{{ item.ArticleStatusCode }} {{ item.ArticleStatusDesc }}</span></ion-label>                  
                                  </ion-item>   -->                           
                                 
                            </ion-card>
    
                </ng-container>
            </ion-row>        


    </ng-container>
        
        <ng-template #noResultsTemplate>
                <ion-row>
                <ion-card> 
        
                  <ion-card-header class="ion-no-padding">
                      
                      <ion-grid>
                              <ion-row>
                                  <ion-col>
                                      <ion-label class="fw600 ion-text-wrap" color="dark"  style="display:block">No Results Found</ion-label>                                                                 
                                  </ion-col>
                                              
                              </ion-row>
                      </ion-grid>  
                    
                  </ion-card-header>                    
              </ion-card>
            </ion-row>
        </ng-template>

        <!--<div *ngIf="barcode">
                <ion-row>
                 BARCODE:  {{ barcode }}
                </ion-row>
        </div> -->
      
        <div *ngIf="!scannerIsRunning && !isSearching">
                <div class="video__explanation">
                  <p>Scan Barcode</p>
                  <p>or</p>
                  <p>Enter search term</p>
                </div>
        </div>

        <div *ngIf="scannerIsRunning">
                <div class="video__container">
                    <div>
                      <div class="video" id="video"><video  autoplay playsinline autofocus="true"></video></div>                     
                    </div>                  
                </div>
        </div>

        <!--<ion-row>            
            <ion-thumbnail>         
                    <img src="{{ viewScanPhoto }}" tappable  />
                </ion-thumbnail>
        </ion-row>

        <ion-row wrap>
           <p> {{ debugOut }} </p>
        </ion-row> -->
        
      </ion-content>

      <ion-footer *ngIf="lastScanResult">
            <ion-toolbar>
                <ion-text> Last Barcode:{{ this.lastScanResult }}</ion-text>
            </ion-toolbar>
      </ion-footer>

         
                    
            
       

