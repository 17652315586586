import { Injectable } from '@angular/core';
import { Platform, NavController, MenuController } from '@ionic/angular';
import { SwUpdate } from "@angular/service-worker";
import { Component,OnInit } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class InteractiveuxService implements OnInit{

  constructor(
    private platform: Platform,
    private swUpdate: SwUpdate,
  ) {   }

  ngOnInit() {

    if (this.swUpdate.isEnabled) {
      this.swUpdate.available.subscribe(event => {
        this.vibez('shortshort');
        if (confirm("An InStore App update is available! Please update now.")) {
          this.swUpdate.activateUpdate().then(() =>  this.reload());
        }
      });
    }

  }
  

  vibez(vibe: any) {
    // window.navigator.vibrate() && window.navigator.vibrate(100);
    console.log('Vibez',vibe);
    
    if(navigator.vibrate) {
      switch(vibe) {
        case "haptic": {
          navigator.vibrate(50);
          break;
        }
        case "shortshort": {
          navigator.vibrate([100,50,100]);
          break;
        }
        case "medshort": {
          navigator.vibrate([150,50,75]);
          break;
        }
        case "triple": {
          navigator.vibrate([200,75,200,75,200]);
          break;
        }
        case "mario": {
          navigator.vibrate([125,75,125,275,200,275,125,75,125,275,200,600,200,600]);
          break;
        }
        case "tmnt": {
          navigator.vibrate([75,75,75,75,75,75,75,75,150,150,150,450,75,75,75,75,75,525]);
          break;
        }
        case "vader": {
          navigator.vibrate([500,110,500,110,450,110,200,110,170,40,450,110,200,110,170,40,500]);
          break;
        }
        case "bond": {
          navigator.vibrate([200,100,200,275,425,100,200,100,200,275,425,100,75,25,75,125,75,25,75,125,100,100]);
          break;
        }
        case "mk": {
          navigator.vibrate([100,200,100,200,100,200,100,200,100,100,100,100,100,200,100,200,100,200,100,200,100,100,100,100,100,200,100,200,100,200,100,200,100,100,100,100,100,100,100,100,100,100,50,50,100,800]);
          break;
        }
        case "smoothcrim": {
          navigator.vibrate([0,300,100,50,100,50,100,50,100,50,100,50,100,50,150,150,150,450,100,50,100,50,150,150,150,450,100,50,100,50,150,150,150,450,150,150]);
          break;
        }
        case "bond": {
          navigator.vibrate([200,100,200,275,425,100,200,100,200,275,425,100,75,25,75,125,75,25,75,125,100,100]);
          break;
        }
      }
    } else {
      console.log('No Vibez on me Bra')
    }
  }


  handleVisibilityChange() {
    if (document.hidden) {
      console.info('Visibility Change: Hidden');
    } else  {
      console.info('Visibility Change: Visible');
    }
  }

  handleConectivityChange() {
    if (navigator.onLine) {
      console.info('Connection Change: Online');
    } else  {
      console.info('Connection Change: Offline');
    }
  }

  reload() { 
          
    this.swUpdate.activateUpdate().then(() => {
        console.log('SwUpdate RELOAD Initiated');
        document.location.reload();                             
    })     
    //window.location.reload();
      
}


}
