import { Injectable } from '@angular/core';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { StoreLocationLookupService} from '../services/storelocationlookup.service';
import { HttpClient   } from '@angular/common/http';
import { Observable, of, observable  } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GpsService {

  liveOptions: any;
  watchOptions: any;
  watchId: any;

  public geoLatitude: number;
  public geoLongitude: number;
  public geoAccuracy: number;
  public geoResults: Observable<any>;
  public geoApprox: boolean = false;

  constructor(
              private geolocation: Geolocation,
              private storelocationlookupService: StoreLocationLookupService
             ) { }

  getLiveLocation(accuracy: any) {

      if(accuracy == 'high') {
       this.liveOptions = {enableHighAccuracy:true, maximumAge:0, timeout:30000};
      } else {
        this.liveOptions = {enableHighAccuracy:false, maximumAge:30000, timeout:27000};
      }
      navigator.geolocation.getCurrentPosition(this.onLocationSuccess, this.onLocationError, this.liveOptions );

  }

  onLocationSuccess(geopos) {
    console.log('LIVE getLocation Success',geopos);
  }

  onLocationError(e) {
    console.log('LIVE getLocation Error', e);
  }

  
  watchLiveLocation() {
    this.watchId = navigator.geolocation.watchPosition(this.watchSuccess, this.watchError, this.watchOptions);
  }

  watchSuccess() {

  }

  watchError() {

  }

  /*
  calculateDistance(lat1, lon1, lat2, lon2) {
    var R = 6371; // km
    var dLat = (lat2 - lat1).toRad();
    var dLon = (lon2 - lon1).toRad(); 
    var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(lat1.toRad()) * Math.cos(lat2.toRad()) * 
            Math.sin(dLon / 2) * Math.sin(dLon / 2); 
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a)); 
    var d = R * c;
    return d;
  }
  Number.prototype.toRad = function() {
    return this * Math.PI / 180;
  }
  */

 async getGeolocation(): Promise<any>{
  // this.isLoading = true;
  if(navigator.geolocation) {
      console.debug('getGeolocation is AVAILABLE (navigator)');
      return await this.geolocation.getCurrentPosition().then((resp) => {
            console.log('GEOLOCATION INFO',resp);

            // this.getCurrentStoreLocation();
            // this.getLocalStoresLocation();
            // resp.coords.approximate = false;
            // resp.coords.timestamp   = resp.timestamp;

            return resp.coords;

      }).catch((error) => {
       console.error('getGeoLocation Catch', error);
       // this.geoApprox = true;
       let geo = this.getGeolocationApproximate();
       console.debug('getGeolocationApproximate: latitude', this.geoLatitude);
       console.debug('getGeolocationApproximate: longitude', this.geoLongitude); 

       return geo;

      });

      
      
    }else{
      console.debug('getGeolocation is UN-AVAILABLE (navigator)');
      
      let geo = this.getGeolocationApproximate();
      console.debug('getGeolocationApproximate', this.geoAccuracy);
    

      return geo;
      
    }
   
}

async getGeolocationApproximate() {

  let geo =  await this.storelocationlookupService.approximateLocation().toPromise().then(data => {

        console.debug('IPGeoLookup', data);        
        
        let coords = [];
        coords['latitude']  = data.IPGeoLookup.latitude;
        coords['longitude'] = data.IPGeoLookup.longitude;
        coords['accuracy']  = data.IPGeoLookup.accuracy;
        coords['approximate'] = true;
        console.debug('GPS Service storelocation lookup - Approx Return', coords);
        
        return coords;
     
   });
  console.debug('GPS Service - Approx Return', geo);
  return geo;

}
  



}